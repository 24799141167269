@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Avenir', sans-serif;
    background-color: #ffffff;
}

body.dark {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Avenir', sans-serif;
    background-color: #111827;
}

footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

@font-face {
    font-family: "Avenir Medium";
    src: local("Avenir Medium"), url("../../public/fonts/Avenir\ Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Avenir Regular";
    src: local("Avenir Regular"), url("../../public/fonts/Avenir\ Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Avenir Book";
    src: local("Avenir Book"), url("../../public/fonts/Avenir\ Book.otf") format("truetype");
    font-style: normal;
    font-weight: 300;
}


/* @font-face {
    font-family: "Geoma Light";
    src: local("Geoma Light"), url("../../public/fonts/Geoma\ Light\ demo.otf") format("truetype");
    font-style: normal;
    font-weight: 300;
} */

@font-face {
    font-family: "Avenir Light";
    src: local("Avenir Light"), url("../../public/fonts/Avenir\ Light.otf") format("truetype");
    font-style: normal;
    font-weight: 300;
}

body {
    font-family: 'Avenir Light', sans-serif;
}

body html,
body a {
    font-family: 'Avenir Light', sans-serif;
}

body li {
    font-family: 'Avenir Light', sans-serif;
}

body li:hover {
    color: #121113;
    font-weight: 700;
}

body input,
body textarea {
    border-radius: 4px;
    border: 0;
    background: rgb(241, 242, 243);
    transition: all 0.3s ease-in-out;
    outline: none;
    width: 100%;
    padding: 1rem 1.25rem;
    font-family: 'Avenir Regular', sans-serif;
}

body input:focus-within,
body textarea:focus-within {
    background: none;
    box-shadow: #2e186a 0px 0px 0px 1px;
}

body h1 {
    font-family: 'Avenir Medium', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
}

body h2 {
    font-family: 'Avenir', sans-serif;
    font-weight: 600;
    font-size: 2rem;
}

body h3 {
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    font-size: 1.75rem;
}

body h4 {
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
}

body h5 {
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
}

body h6 {
    font-family: 'Avenir', sans-serif;
    font-weight: 500;
    font-size: 1rem;
}

body p {
    font-family: 'Avenir Book', sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

body a {
    font-family: 'Avenir Book', sans-serif;
    text-decoration: none;
    outline: none;
    color: black;
}

body a:hover {
    color: #2e186a;
}

body *:focus {
    outline: none;
    font-family: 'Avenir Book', sans-serif;
}

body {
    font-family: 'Avenir Book', sans-serif;
}

body .about-block-image svg {
    text-align: center;
}


/* Add this to your styles.css file */

body .avenir-medium {
    font-family: 'Avenir Medium', sans-serif;
    font-weight: 500;
}

body input:focus {
    outline: none;
}

:root {
    --line-color: #913B93;
    background-color: #ffffff;
}

:root.dark {
    --line-color: #D6E53D;
    background-color: #111827;
}

.skeleton-primary {
    background-color: #1976d2;
}

.skeleton-primary.dark {
    background-color: #D6E53D;
    /* Example primary color */
    /* Add other styling as needed */
}